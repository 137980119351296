/*  this mixin contains logic to set filters based on selected data */

const querystringMixin = {    
    methods: {        
        setQueryStringParam(selectedFilters, queryParam) { 
            if(selectedFilters) {
                // Set filter parameters list
                Object.keys(selectedFilters).map((key) => {
                    if(selectedFilters[key] || selectedFilters[key] === 0)
                    {
                        queryParam[key] = encodeURIComponent(selectedFilters[key])
                    }
                    return null
                })
            } 

            if(JSON.stringify(this.$route.query) !== JSON.stringify(queryParam)) {
                this.$router.replace({ query: queryParam })                 
            }
        }
    }
}

export default querystringMixin
