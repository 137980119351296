import { navigationTabs } from '../pages/Portfolio/CourseEdit/courseEditHelpers'
import { SHARED } from '@/../../shared/constants/EdumsSharedConstants'
import { mandatoryFieldsError } from '../../../shared/constants/validationMessageHelper'

const tabMixin = {
    data() {
        return {
            SHARED,
            navigationTabs,
            isLastTab: this.lastTab
        }
    },
    props: {
        activeTab: {
            type: String,
            default: null
        }
    },
    beforeDestroy() {
        this.$bus.off('triggerTabNavClick')
    },
    mounted() {  
        this.$bus.on('triggerTabNavClick', this.handleClickOnTab)
    },
    computed: {
        getCourseId() {
            return this.$route.params.id
        },
        isOnCourseCreatePage() {
            return this.$route.name === 'course-create'
        },
        isOnCourseEditPage() {
            return this.$route.name === 'course-details'
        },
        isOnCourseDuplicatePage() {
            return this.$route.name === 'course-duplicate'
        },
        lastTab() {
            return this.activeTab === this.navigationTabs[this.navigationTabs.length - 1].path
        },
        invalidInvoices() {
            // Start of invoices tab validations
            let isInvalid = false
            
            if(this.$refs.startMomentsInvoiceItems?.length > 0) {
                this.$refs.startMomentsInvoiceItems.forEach(invoice => { 
                    invoice.$v.invoiceItems.$touch()
                })
               
                const invoiceItemErrors = this.$refs.startMomentsInvoiceItems.some(invoice => {
                   return  invoice.$v.invoiceItems.$error
                })

                if (invoiceItemErrors) {
                    isInvalid = true
                }
            }

            if(this.$refs.invoiceItems){
                this.$refs.invoiceItems.$v.invoiceItems.$touch()

                if(this.$refs.invoiceItems.$v.invoiceItems.$invalid) {
                    isInvalid = true
                }
            }
            return isInvalid
        }
    },
    methods: {
        tabButtonAction() {
            if (!this.isOnCourseEditPage) {
                return this.handleClickOnTab(null, 'next-tab')
            }

            return this.handleClickOnTab(null, 'update-course')
        },
        handleClickOnTab(tab, tabDirection) {
            this.$v?.$touch() 

            if ((this.activeTab === 'course-planning' || this.activeTab === 'course-invoices') && this.planningAndInvoicesData?.learningMethod?.planning?.startMoments) {
                this.planningAndInvoicesData?.learningMethod?.planning?.startMoments.forEach(startMoment => {
                    startMoment.isActive = false
                })
            }


            if (this.$v?.$invalid || this.invalidInvoices) { //If there are errors change the states of the tabs
                if (tab) { //Only enters this "if" when the click comes from the tabs
                    tab.tabFrom.hasErrors = true
                    tab.tabFrom.isValid = false
                } 
                else { //Only enters this "else" when the click comes from the buttons 
                    this.navigationTabs.map(navigationTab => {
                        if (navigationTab.path === this.activeTab) {
                            navigationTab.hasErrors = true
                            navigationTab.isValid = false
                        }
                    })
                }
                
                this.showToastError()
                return
                
            } else {
                if (tab) {
                    tab.tabFrom.isValid = true
                }else {
                    this.navigationTabs.map(navigationTab => {
                        navigationTab.hasErrors = false
                    })
                }
               
            }
           
            //Tab logic
            if(tab?.tabFrom.path === this.activeTab && !tabDirection) {
                if (!this.$v?.$invalid || !this.invalidInvoices) {
                    this.$emit('tabChange', tab.tabTo.path)
                    tab.tabTo.isDisabled = false
                    tab.tabFrom.hasErrors = false
                }
            }

            //Button logic
            const currentTabIndex =  this.navigationTabs.map(tab => tab.path).indexOf(this.activeTab)
            let tabTo

            if(tabDirection === 'next-tab' && !this.isOnCourseEditPage) {
                tabTo = this.navigationTabs[currentTabIndex + 1]?.path 

                this.$emit('tabChange', {currentTabIndex, tabTo}) 
            }

            if(tabDirection === 'previous-tab' && !this.isOnCourseEditPage) {
                tabTo = this.navigationTabs[currentTabIndex - 1]?.path  

                this.$emit('tabChange', {currentTabIndex, tabTo}) 
            }

            if (tabDirection === 'update-course') {
                this.updateEditedCourse({startMomentStatus: 'active'})
            }
        },
        showToastError() {
            const toastNotification = {
                type: 'error',
                message: mandatoryFieldsError
            }

            this.$store.dispatch('toastNotificationModule/add', toastNotification, {
                root: true
            })    
        }
    }
}

export default tabMixin 
