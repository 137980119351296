const filterMixin = {
    data() {
        return {
            resetPag: false
        }
    },
    created() {
        // Code that will run only after the entire view has been rendered
        // We need this otherwise the token will not be sent in the request header through axios
      
        this.setSelectedFiltersFromQuery()
        this.$bus.on('list-ready', this.getUpdatedList)            
       
    },
    beforeDestroy() {
        this.$bus.off('list-ready') 
    },
    methods: {
        setSpecificFilter(key, newValue) {
            this.selectedFilters[key] = newValue
            
            // If there is a vue validate defined, check if all fields are valid before emit
            if (this.$v && this.$v.$invalid) {
                return
            }
            this.$bus.emit('update-selected-filters', { selectedFiltersInput: this.selectedFilters, resetPagination:true })
        },
        setSelectedFiltersFromQuery(){    
            if(Object.keys(this.$route.query).length !== 0)
            {
                Object.keys(this.selectedFilters).forEach((key) => {
                    if(this.$route.query[key]){
                        this.selectedFilters[key] = unescape(this.$route.query[key])
                    } else if(this.$route.query[key] === '')
                    {
                        this.resetPag = true
                    }

                })               
            }
        },
        getUpdatedList(){    
            // If there is a vue validate defined, check if all fields are valid before emit
            if (this.$v)  
            {
                this.$v.$touch()
                if (this.$v.$invalid)  {
                    return
                }
            }
            this.$bus.emit('update-selected-filters', { selectedFiltersInput: this.selectedFilters, resetPagination: this.resetPag })
        }
    }
}

export default filterMixin
